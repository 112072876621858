import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import './style.css';

const FilterList = ({ data, testDevice, testColor }) => {
  const [filter, setFilter] = useState('');
  const filteredDevices = data && data?.filter(i => (i.device_ipv4_address.includes(filter) || i.roclub_number.includes(filter)))
    .sort((a, b) => (a.updated_ap < b.updated_ap) ? -1 : ((a.updated_ap > b.updated_ap) ? 1 : 0));

  const handleFilterChange = e => setFilter(e.target.value);

  return (
    <div>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Filter IP address or roclub_number"
          value={filter}
          onChange={handleFilterChange}
          style={{ maxWidth: "300px", margin: "0 auto" }}
        />
      </Form.Group>
      <Table striped bordered hover style={{ width: "96% !important" }}>
        <thead>
          <tr>
            {/* <th>Date</th> */}
            <th>Roclub Number</th>
            <th>IP</th>
            <th>Status</th>
            <th>Test</th>
          </tr>
        </thead>
        <tbody>
        {filteredDevices && filteredDevices.filter(i => i.device_provisioning_status === "Success").map((item, index) => (
          <tr key={index} style={{ background: testColor[item.roclub_number] || 'inherit' }}>
            {/* <td>{item.updated_at?.split("T")[0]} {item.updated_at?.slice(11, 16)}</td> */}
            <td>{item.roclub_number}</td>
            <td>{item.device_ipv4_address}</td>
            <td>{item.device_provisioning_status}</td>
            <td><Button onClick={_e => testDevice({ roclub_number: item.roclub_number })}>Test</Button></td>
          </tr>
        ))}
        </tbody>
      </Table>
    </div>
  );
};

export default FilterList;
