import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from '../../authConfig';
import FilterList from './Filter';
import './style.css';

const fetchWithToken = async ({ url, token, instance }) => {
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }, 
  });

  if (res.status == 401) {
    instance.logoutPopup();
    instance.clearCache();
    return
  }

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return res.json()
}

const DashboardSuccess = ({ API }) => {
  const [testColor, setTestColor] = useState({ '1243-123-1232': 'green' });
  const { instance, inProgress, accounts } = useMsal();
  const [token, setToken] = useState(null);

  const { data, error, isLoading } = useSWR(
    token ? [API, token] : null,
    ([url, token]) => fetchWithToken({ url, token, instance }),
    { refreshInterval: 5000, revalidateOnReconnect: true }
  );

  useEffect(() => { console.log("load") }, [])
  useEffect(() => console.log({error}), [error])

  useEffect(() => {
    if (!data && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // console.log({accessToken});
          setToken(accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                setToken(accessToken);
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
          // Token acquisition failed, it might be expired
          // Perform logout and clear cache
          console.log("logout user and clear cache")
          instance.logoutPopup();
          instance.clearCache();
        });
      }
    })

  const testDevice = async({ roclub_number }) => {

    let env, token;
    const hostname = window.location.hostname;

    if (hostname.includes('localhost') || hostname.includes('.dev')) {
      env = 'dev';
      token = 'hueNgeithuwoa7choesohX0ahXahng';
    } else if (hostname.includes('.stg')) {
      env = 'stg';
      token = 'phahQuauZiefoo2uare4EMa2Oet6le';
    } else if (hostname.includes('.prd')) {
      env = 'prd';
      token = 'hiaxaimi3Ail1aef9eiz3hue9kaiCh';
    }

    try {
      const res = await fetch(`https://api.${env}.roclub.io/api/v1/connector/test/connection?connector=${roclub_number}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(res);
      // const data = await res.json();
      // console.log({ status: res.status, res, data });

      switch (res.status) {
        case 200: {
          setTestColor(state => ({ ...state, [roclub_number]: 'green' }));
          break;
        }
        // case 404: {
        //   setTestColor(state => ({ ...state, [roclub_number]: 'grey' }));
        //   break;
        // }
        default: {
          setTestColor(state => ({ ...state, [roclub_number]: 'red' }));
        }
      }

    } catch (err) {
      console.log("err", err);
    }
  }

  //if (error) return <div>failed to load {JSON.stringify(error)}</div>
  if (isLoading) return <div>loading...</div>

  return <FilterList data={data} testDevice={testDevice} testColor={testColor}/>
};

export default DashboardSuccess;