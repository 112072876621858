import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Table from 'react-bootstrap/Table';
import './style.css';
import trash from '../../assets/images/trash-2.svg';

const statusOrder = ['In Progress', 'New', 'Failure', 'Disconnected', 'Network Error', 'Success'];

const FilterList = ({ data, updateDevice, resetDevice, deleteDevice }) => {
  const [filter, setFilter] = useState('');
  const [qrCode, setQrCode] = useState('');
  const filteredDevices = data && data?.filter(i => (i.device_ipv4_address.includes(filter) || i.device_provisioning_status.includes(filter)))
    .sort((a, b) => {
      const statusA = statusOrder.indexOf(a.device_provisioning_status);
      const statusB = statusOrder.indexOf(b.device_provisioning_status);
      return statusA - statusB;
  });
  // https://www.app.roclub.io/register/device?sn=0410-1232-213-121
  const handleFilterChange = e => setFilter(e.target.value);
  const handleQrDevice = async ({ target: { value } }, ip) => {
    let roclubNumber;
    const url = "https://www.app.roclub.io/register/device?sn=";
    if ((value).includes(url)) {
      const index = value.indexOf(url);
      roclubNumber = value.substring(index + url.length);
    } else {
      roclubNumber = value;
    }
    setQrCode(roclubNumber);

    await updateDevice({ e: {}, roclubNumber, ip });
    // setQrCode("");
  }

  const Popup = ({ ip }) => {
    return (
      <>
        <div className="boxXXXX">
          <a href="#popup1">
            <img src={trash} className="trash"/>
          </a>
        </div>

        <div id="popup1" className="overlay">
          <div className="popup">
            <h1>Are you sure?</h1>
            <a className="close" href="">&times;</a>
            <div className="content">
              Delete the roclub name from ip <br/> <b>{`${ip}`}</b>
            </div>
            <div className='divider'/>
            <a href="" className="button pop">Cancel</a>
            <div className='divider'/>
            <a href="" onClick={e => resetDevice(e, ip)} className="button pop">OK</a>
          </div>
        </div>
      </>
    )
  }

  return (
    <div>
      <Form.Group>
        <Form.Control
          type="text"
          placeholder="Filter IP address or status"
          value={filter}
          onChange={handleFilterChange}
          style={{ maxWidth: "300px", margin: "0 auto" }}
        />
      </Form.Group>
        <Table striped bordered hover style={{ width: "96% !important" }}>
            <thead>
                <tr>
                  <th>Roclub Number</th>
                  <th>IP</th>
                  <th>Status</th>
                  <th>QR Code</th>
                  <th>Remove</th>
                  {/* <th>Input</th> */}
                </tr>
            </thead>
            <tbody>
            {filteredDevices && filteredDevices.filter(i => i.device_provisioning_status !== "Success").map((item, index) => (
              <tr key={index}>
                <td>{item.roclub_number}</td>
                <td>{item.device_ipv4_address}</td>
                <td>{item.device_provisioning_status}</td>
                  {process.env.NODE_ENV !== "development" &&
                    <td>
                        {!item.roclub_number 
                          ? <Link className='button' to={`/scanner/${item.device_ipv4_address}`}>Scan</Link> :
                          item.roclubNumber != "" && item.device_provisioning_status == "Failure"
                          ? <a href="" onClick={e => updateDevice({ e, roclubNumber: item.roclub_number , ip: item.device_ipv4_address })} className="button pop">Update</a>
                        : ""}
                    </td>
                  }
                  {process.env.NODE_ENV === "development" &&
                    <td>
                        {!item.roclub_number 
                          ? <Link className='button' to={`/scanner/${item.device_ipv4_address}`}>Scan</Link> :
                          item.roclubNumber != "" && item.device_provisioning_status == "Failure"
                          ? <a href="" onClick={e => updateDevice({ e, roclubNumber: item.roclub_number , ip: item.device_ipv4_address })} className="button pop">Update</a>
                          : <></>}
                          {/* : <Button onClick={e => resetDevice(e, item.device_ipv4_address)}><img src={trash} className="trash"/></Button>} */}
                        {/* : <Popup key={Math.random()} ip={item.device_ipv4_address} />} */}
                    </td>
                    }
                  <td><Button title="Remove from DB" className='btn btn-secondary' onClick={_ => deleteDevice(item.device_ipv4_address)}><img src={trash} className="trash"/></Button></td>
                  {/* <td>
                    {!item.roclub_number &&
                      <Form.Control
                        type="text"
                        placeholder=""
                        value={qrCode}
                        onChange={e => handleQrDevice(e, item.device_ipv4_address)}
                        style={{ maxWidth: "100px", margin: "0 auto" }}
                      />
                    }
                </td> */}
              </tr>
            ))}
            </tbody>
        </Table>
    </div>
  );
};

export default FilterList;
