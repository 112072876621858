import React, { useEffect, useRef, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';

function Home({ API }) {
  const firstUpdate = useRef(true);
  const { instance, accounts, inProgress } = useMsal();
  const [accessToken, setAccessToken] = useState(null);

  const name = accounts[0] && accounts[0].name;

  function RequestAccessToken() {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      instance.acquireTokenSilent(request).then((response) => {
          setAccessToken(response.accessToken);
      }).catch((e) => {
          instance.acquireTokenPopup(request).then((response) => {
              setAccessToken(response.accessToken);
          });
      });
  }

  useEffect(() => {
    if (firstUpdate.current === true) {
      firstUpdate.current = false;
      return;
    }

    fetch(`${API}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => res.json()).then(data => console.log(data))


    console.log({accessToken})
  }, [accessToken])

  return (
      <>
          {/* <h5 className="card-title">Welcome {name}</h5> */}
          {accessToken ? 
              <p>Access Token Acquired!</p>
              :
              <button variant="secondary" onClick={RequestAccessToken}>Request Access Token</button>
          }
      </>
  );
};

export default Home