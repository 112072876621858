import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import { useMsal } from "@azure/msal-react";
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from '../../authConfig';
import FilterList from './Filter';
import './style.css';

const fetchWithToken = async ({ url, token, instance }) => {
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    }, 
  });

  if (res.status == 401) {
    instance.logoutPopup();
    instance.clearCache();
    return
  }

  // If the status code is not in the range 200-299,
  // we still try to parse and throw it.
  if (!res.ok) {
    const error = new Error('An error occurred while fetching the data.')
    // Attach extra info to the error object.
    error.info = await res.json()
    error.status = res.status
    throw error
  }

  return res.json()
}

const Dashboard = ({ API }) => {
  const { instance, inProgress, accounts } = useMsal();
  const [token, setToken] = useState(null);

  const { data, error, isLoading } = useSWR(
    token ? [API, token] : null,
    ([url, token]) => fetchWithToken({ url, token, instance }),
    { refreshInterval: 5000, revalidateOnReconnect: true }
  );

  useEffect(() => { console.log("load") }, [])
  useEffect(() => console.log({error}), [error])

  useEffect(() => {
    if (!data && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // console.log({accessToken});
          setToken(accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                setToken(accessToken);
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
          // Token acquisition failed, it might be expired
          // Perform logout and clear cache
          console.log("logout user and clear cache")
          instance.logoutPopup();
          instance.clearCache();
        });
      }
    })

  const resetDevice = async (event, ip) => {
    // event.preventDefault();

    const deviceData = {
      device_ipv4_address: ip,
      roclub_number: "",
      device_provisioning_status: "Reset",
      edge_version: "",
    }

    return console.log("Reset Device: ", deviceData);

    try {
      const response = await fetch(`${API}/reset`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(deviceData)
      });
      const result = await response.json();
      console.log("result", result);
      // location.reload();
    } catch (err) {
      console.log("err", err);
    }
  }

  const deleteDevice = async (ip) => {

    try {
      const response = await fetch(`${API}/delete/${ip}`, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const result = await response.json();
      location.reload();
    } catch (err) {
      console.log("err", err);
    }
  }

  // in case of Failure, resend to topic
  const updateDevice = async({ e, roclubNumber, ip }) => {
    e?.preventDefault?.();

    const deviceData = {
      roclub_number: roclubNumber,
      device_ipv4_address: ip,
      device_provisioning_status: "In Progress"
    }

    try {
      await fetch(`${API}`, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(deviceData)
      });
      // location.reload();
    } catch (err) {
      console.log("err", err);
    }
  }

  //if (error) return <div>failed to load {JSON.stringify(error)}</div>
  if (isLoading) return <div>loading...</div>

  return (
    <>
      <FilterList data={data} updateDevice={updateDevice} resetDevice={resetDevice} deleteDevice={deleteDevice}/>
    </>
  );
};

export default Dashboard