import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';;
import { useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from "@azure/msal-browser";
import Html5QrcodePlugin from '../../components/HtmlQrScanner';

const Scanner = ({ API }) => {
  const { ip } = useParams();
  const navigate = useNavigate();
  const firstUpdate = useRef(true);
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const { instance, inProgress, accounts } = useMsal();

  useEffect(() => {
    if (!data && inProgress === InteractionStatus.None) {
      const accessTokenRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          setToken(accessToken);
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance
              .acquireTokenPopup(accessTokenRequest)
              .then(function (accessTokenResponse) {
                // Acquire token interactive success
                let accessToken = accessTokenResponse.accessToken;
                setToken(accessToken);
              })
              .catch(function (error) {
                // Acquire token interactive failure
                console.log(error);
              });
          }
          console.log(error);
        });
      }
    })

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (!data || !token) return console.error("Token or roclub number missing");

    const updateDevice = async() => {

      const deviceData = {
        roclub_number: data,
        device_ipv4_address: ip,
        device_provisioning_status: "In Progress"
      }

      try {
        await fetch(`${API}`, {
          method: 'PUT',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(deviceData)
        });
        navigate("/", { state: {} });
      } catch (err) {
        console.log("err", err);
      }
    }

    updateDevice();
  
  }, [data])

  const onNewScanResult = (decodedText, _decodedResult) => {

    let roclubNumber;
    const url = "https://www.app.roclub.io/register/device?sn=";
    if ((decodedText).includes(url)) {
      const index = decodedText.indexOf(url);
      roclubNumber = decodedText.substring(index + url.length);
    } else {
      roclubNumber = decodedText;
    }

    setData(roclubNumber);
  }; 

  return (
    <>
      <h1>QR-Code Scanner</h1>
      <div id="Scanner">
        <p>Data: {data && data}</p>
        <Html5QrcodePlugin
          fps={10}
          qrbox={250}
          disableFlip={false}
          qrCodeSuccessCallback={onNewScanResult}
        />
      </div>
      <p>{data}</p>
    </>
  );
};

export default Scanner;